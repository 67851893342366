import * as React from "react"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import LoginPage from "../pages/login/LoginPage"
import Header from "./header/Header"
import { LoginContext, Spinner } from "social-supermarket-components"
import { colors } from "social-supermarket-model"

const Container = styled.div`
  background-color: ${({ whiteBackground }) => (whiteBackground ? "white" : colors.backgroundGrey)};
`

const Page = styled.div``
const BottomContainer = styled.div`
  padding-top: 50px;
  display: flex;
  width: 100vw;
`
const Body = styled.div`
  flex: 1;
  width: 100vw;
  min-height: calc(100vh - 50px);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.2s ease-in-out;
`

interface Props {
  children?: React.ReactNode
  whiteBackground?: boolean
}
const Layout = (props: Props) => {
  const { children, whiteBackground } = props
  const { user, loggedInUser, initialising, loggingOut } = useContext(LoginContext)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const renderBody = () => {
    if (initialising) {
      return <Spinner isLoading={true} label={"Logging In.."} />
    } else if (!loggedInUser) {
      return <LoginPage />
    } else {
      return <Page>{children}</Page>
    }
  }

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <Container whiteBackground={whiteBackground}>
      <Header />
      <BottomContainer>
        <Body user={user} isVisible={isVisible}>
          <Spinner isLoading={loggingOut} label={"Logging Out.."} />
          {renderBody()}
        </Body>
      </BottomContainer>
    </Container>
  )
}

export default Layout
