import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { colors } from "social-supermarket-model"

const Container = styled.div`
  display: flex;
  padding: 0 0px;
  font-size: 0.8em;
`
const Option = styled.div`
  padding: 5px 30px;
  border: 1px solid ${({ selected }) => (selected ? colors.primaryDark : colors.unselectedTextGrey)};
  cursor: pointer;
  width: 170px;
  display: flex;
  justify-content: center;
  color: ${({ selected }) => (selected ? colors.primaryDark : colors.unselectedTextGrey)};
  text-shadow: ${({ selected }) =>
    selected ? `0.5px 0px 0.5px ${colors.primaryDark};` : "normal"};
  background: ${({ selected }) => (selected ? "rgba(16, 93, 110, 0.1)" : "none")};

  &:first-of-type {
    border-right: ${({ selected }) => (selected ? `1px solid ${colors.primaryDark}` : "none")};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-of-type {
    border-left: ${({ selected }) => (selected ? `1px solid ${colors.primaryDark}` : "none")};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

interface OptionType {
  label: string
  key: string
}

interface Props {
  options: OptionType[]
  onSelect: (key: string) => void
  selected: string
}

const ToggleButton: FC<Props> = ({ options, onSelect, selected }) => {
  return (
    <Container>
      {options.map(option => (
        <Option
          onClick={() => onSelect(option.key)}
          key={option.key}
          selected={option.key === selected}
        >
          {option.label}
        </Option>
      ))}
    </Container>
  )
}

export default ToggleButton
