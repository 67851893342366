import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import { ArcElement, Chart, Legend, Tooltip } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { ChoroplethController } from "chartjs-chart-geo"
import Donut from "./Donut"
import { Card } from "social-supermarket-components"
import { Panel } from "rsuite"

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels, ChoroplethController)

const Container = styled(Card)`
  padding: 20px;
  flex: 1;
`

interface Props {
  className?: string
  data: { [key: string]: number }
  title: string
}

const DonutSection: FC<Props> = ({ className, data, title }) => {
  return (
    <Panel header={title} className={className}>
      <Donut data={data} />
    </Panel>
  )
}

export default DonutSection
