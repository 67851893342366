import { getConfig } from "../../config/index"
import {
  CertificationType,
  ImpactOrderType,
  ImpactSupplierType,
  ImpactTypeType,
  ProblemType,
  SdgType,
  SsmgType,
  api,
} from "social-supermarket-model"

export interface ImpactResponseType {
  certifications: { [key: string]: CertificationType }
  impactTypes: { [key: string]: ImpactTypeType }
  orderImpacts: ImpactOrderType[]
  allOrderImpacts: ImpactOrderType[]
  problems: { [key: string]: ProblemType }
  sdgs: { [key: string]: SdgType }
  ssmgs: { [key: string]: SsmgType }
  suppliers: { [key: string]: ImpactSupplierType }
}

export const getImpact = async (
  token: string,
  userId: number,
  startDate: string,
  endDate: string
): Promise<ImpactResponseType> => {
  return api.platformGet(
    `${
      getConfig().baseUrl
    }/wp-json/social-supermarket/v1/impact/get-impact?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
    token
  )
}
