import * as React from "react"
import { FC, useContext } from "react"
import styled from "styled-components"
import { LoginContext } from "social-supermarket-components"
import Layout from "../social-supermarket/components/Layout"
import SEO from "../social-supermarket/components/Seo"
import ImpactDashboard from "../social-supermarket/pages/impact/ImpactDashboard"

const Container = styled.div``

interface Props {}

const Impact: FC<Props> = () => {
  const loginContext = useContext(LoginContext)
  return loginContext.user ? (
    <Layout>
      <SEO title={"Impact Dashboard"} description={""} />
      <ImpactDashboard />
    </Layout>
  ) : (
    <Layout />
  )
}

export default Impact
