import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { SsmgType } from "social-supermarket-model"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const IconContainer = styled.div`
  width: 60%;

  svg {
    margin-bottom: -3px;
  }
`
const Label = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.35em;
`

interface Props {
  className?: string
  includeLabel?: boolean
  ssmg: SsmgType
}

const ImpactIcon: FC<Props> = ({ className, includeLabel, ssmg }) => {
  console.log("SSMG", ssmg)
  return (
    <Container className={className}>
      <IconContainer dangerouslySetInnerHTML={{ __html: ssmg.plainSvg }} />
      {includeLabel && <Label>{ssmg.name}</Label>}
    </Container>
  )
}

export default ImpactIcon
